import { relationships } from '../../config/statics';
import { createAsyncThunk } from '@reduxjs/toolkit';
import errorFormatter from '../../helpers/errorFormatter';

export const addNewCompany = createAsyncThunk(
  'company/add',
  async (company, { rejectWithValue, getState }) => {
    const response = await fetch('/api/companies', {
      method: 'POST',
      body:   JSON.stringify(company)
    });
    const body = await response.json();

    if (!body.success || response.status < 200 || response.status >= 300) {
      if (body?.message?.match(/companies_ein_key/)) {
        return rejectWithValue(errorFormatter(body.message, 'Company'));
      }
      return rejectWithValue(body.message);
    }

    if (company.files) {
      let awsRes = await fetch('/api/aws-s3', {
        method: 'POST',
        body:   JSON.stringify({
          files:     company.files,
          companyId: body.company[0].id
        })
      });
      if (awsRes.status < 200 || awsRes.status >= 300) {
        return rejectWithValue(awsRes.message);
      }
    }
    delete company.files;

    return {
      success: true,
      company: body.company
    };
  }
);

export const fetchCompanyByPage = createAsyncThunk(
  'company/fetchByPage',
  async ({ page, query, per_page }, { rejectWithValue, getState }) => {
    let session = {};
    const state = await getState(),
          isAdmin = await state?.configList?.configs?.isAdmin || session?.user?.role === 'admin',
          userID = await state?.configList?.configs?.userId,
          role = await state?.configList?.configs?.role || session?.user?.role,
          brokerIds = await state?.configList?.configs?.brokerIds,
          brokerID = await state?.configList?.configs?.title === 'broker' && userID;

    if (!userID) {
      return  rejectWithValue('NOPE');
    }

    let where;

    if (userID && (brokerID || (brokerIds || []).length)) { //csr
      where = isAdmin ? {} : { OR: [{ brokerId: { in: (brokerIds || []).length ? brokerIds : [ brokerID ] } }, { contactId: userID }] };
    }
    if (userID && !(brokerID || role === 'broker' || (brokerIds || []).length)) { //company user
      where = isAdmin ? {} : { contactId: userID };
    }
    if (query?.company) {
      where = { id: query.company };
    }

    if (query?.broker) {
      where = { brokerId: query.broker };
    }

    if (query?.contact) {
      where = { contactId: query.contact };
    }
    if (!where) {
      return rejectWithValue('NOPE');
    }

    const filterMap = {
      quotedOn:       { 'quotedOn': { 'isSet': true }  },
      completedQuery: {
        'requestedQuoteOn': { 'isSet': true },
        'quotedOn':         { 'isSet': false }
      },
      inProgress: {
        'requestedQuoteOn': { 'isSet': false },
        'quotedOn':         { 'isSet': false }
      }
    };

    if (query.filters.length) {
      const OR = [];
      query.filters.map(qf=>{
        OR.push(filterMap[qf]);
      });
      where = {
        ...where,
        OR
      };
    }

    const url = '/api/companies?';
    const params = new URLSearchParams({
      page,
      per_page,
      isCompUser: state?.configList?.configs?.title === 'companyUser',
      where:      JSON.stringify(where),
      include:    relationships?.company
    });

    const response =  await fetch(url + params);
    const data = await response.json();

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(data);
    }

    return data;
  }
);

export const updateHFQ = createAsyncThunk(
  'company/updateHFQ',
  async ({ company, employee, extendHfq = false }, { rejectWithValue, getState }) => {
    const params = new URLSearchParams({
      ...company?.id && !employee?.id ? { companyId: company?.id } : {},
      ...employee?.id ? { employeeId: employee?.id } : {},
      ...extendHfq ? { hfqId: employee.healthFormStatusRecord.id } : {}
    });

    const method = extendHfq ? 'PATCH' : 'DELETE';
    const response = await fetch('/api/hfq?' + params, { method });
    const { hfq } = await response.json();

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue();
    }

    if (employee?.id) {
      return {
        ...company,
        status:          extendHfq,
        employeeRecords: company.employeeRecords.map(empRec => {
          let updatedEmployee = empRec;
          if (empRec.id === employee.id) {
            updatedEmployee = {
              ...updatedEmployee,
              healthFormStatusRecord: {
                ...extendHfq ? empRec.healthFormStatusRecord : {},
                ...extendHfq ? { deleteBy: hfq.deleteBy } : {}
              },
              status: extendHfq
            };
          }
          return updatedEmployee;
        })
      };
    }

    return {
      ...company,
      // eslint-disable-next-line no-unused-vars
      employeeRecords: company.employeeRecords.map(({ healthFormStatusRecord, ...emp }) => ({
        ...emp,
        status: false
      })),
      status: false
    };
  }
);


export const updateCompany = createAsyncThunk(
  'company/update',
  async (company, { rejectWithValue, getState }) => {
    const response = await fetch('/api/companies', {
      method: 'PUT',
      body:   JSON.stringify(company)
    });
    const data = await response.json();
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(data);
    }

    return data.updatedCompany;
  }
);

export const quoteCompany = createAsyncThunk(
  'company/quote',
  async ({ files, companyId } = {}, { rejectWithValue, getState }) => {
    const attachments = [];
    for (const file in files) {
      if (Object.prototype.hasOwnProperty.call(files, file)) {
        const uInt8File = new Uint8Array(await files[file].arrayBuffer());
        attachments.push({
          contentType: files[file].type,
          name:        files[file].name,
          data:        uInt8File
        });
      }
    }

    const response = await fetch('/api/quotes', {
      method: 'POST',
      body:   JSON.stringify({
        attachments,
        companyId
      })
    });

    const { company, count } = await response.json();

    return {
      company,
      count
    };
  }
);

export const requestQuote = createAsyncThunk(
  'company/requestQuote',
  async ({ steps, companyId } = {}, { rejectWithValue, getState }) => {
    const response = await fetch('/api/quotes', {
      method: 'PUT',
      body:   JSON.stringify({
        steps,
        companyId
      })
    });

    const { message, success, company } = await response.json();

    return company;
  }
);

export const unlinkEmployee = createAsyncThunk(
  'employee/unlinkFromComp',
  async ({ employeeId, companyId }, { rejectWithValue, getState }) => {
    const response =  await fetch('/api/companies', {
      method: 'PATCH',
      body:   JSON.stringify({
        employeeId,
        companyId
      })
    });
    const body = await response.json();
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(body);
    }

    return body.updatedCompany;
  }
);
