
import { SET_FORM_DATA, FORM_DATA_ERROR, RESET_FORM_DATA } from '../types';

const initialState = { loading: true };

export default function formDataReducer (state = initialState, action) {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case RESET_FORM_DATA:
      return initialState;
    case FORM_DATA_ERROR:
      return {
        loading: false,
        error:   action.payload
      };
    default: return state;
  }
}
