import { createSlice, isFulfilled } from '@reduxjs/toolkit';
import { fetchUsersByPage, unlinkBrokerFromCSR, addUser } from '../actions/userAction';
import { updateCompany } from '../actions/companyAction';

export const userSlice = createSlice({
  name:         'users',
  initialState: {
    value:        0,
    statusByName: { addingUsers: null },
    dataByName:   { }
  },
  reducers: {
    clearUserStatus: (state, action) => {
      state.statusByName.addingUsers = false;
    }
  },
  extraReducers: (builder) => {
    let arr = [{
      name: 'USER_PAGINATION_FETCH',
      ...fetchUsersByPage
    }, {
      name: 'BROKER_UNLINK_CSR',
      ...unlinkBrokerFromCSR
    }, {
      name: 'USER_ADDING',
      ...addUser
    }];

    arr.forEach(a => {
      builder.addCase(a.pending, (state, action) => {
        state.statusByName[a.name] = 'pending';
      })
      .addCase(a.fulfilled, (state, action) => {
        let page = action.meta.arg.page;
        state.statusByName[a.name] = 'fulfilled';
        state.statusByName.page = page;
        if (action.type === 'broker/unlinkFromCSR/fulfilled') {
          state.dataByName.users.csrs = action.payload.csrs;
        }
        if (action.type === 'user/add/fufilled') {
          state.dataByName.users.push(...action.payload);
        }
        if (action.type === 'user/fetchByPage/fulfilled') {
          state.dataByName.users = {
            brokers:    action.payload.brokers,
            allUsers:   action.payload.allUsers,
            csrs:       action.payload.csrs,
            allBrokers: action.payload.allBrokers
          };
          state.dataByName.brokerCount = action.payload.brokerCount;
          state.dataByName.csrCount = action.payload.csrCount;
          state.dataByName.totalUserCount = action.payload.allUserCount;
        }
      })
      .addCase(a.rejected, (state, action) => {
        state.statusByName[a.name] = 'rejected';
      });
    });

    builder.addMatcher(isFulfilled(addUser), (state, action) => {
      let updatedCSRs = state.dataByName.users.csrs || [];
      const brokers = state.dataByName.users.allBrokers;
      const newBrokers = brokers.filter(({ id }) => action.payload.csrs.brokerIds.includes(id));

      if (action.payload.csrs && !updatedCSRs.filter(csr => csr.id === action.payload.csrs.id).length) {
        updatedCSRs.push(action.payload.csrs);
      }

      updatedCSRs = updatedCSRs.map(csr => {
        let updatedCSR = csr;
        if (action.payload.csrs.id === csr.id) {
          updatedCSR.brokers = newBrokers;
        }
        return updatedCSR;
      });

      state.dataByName.users.csrs = updatedCSRs;
    });

    builder.addMatcher(isFulfilled(updateCompany), (state, action) => {
      let updatedBrokers = state.dataByName.users.brokers;
      const updatedCompany = action.payload;

      updatedBrokers = updatedBrokers.map(broker => {
        if (broker.id === updatedCompany.brokerId) {
          broker.brokerCompanies.push(updatedCompany);
        }

        broker.brokerCompanies = broker.brokerCompanies.filter((company) => {
          if (updatedCompany.id === company.id && updatedCompany.brokerId !== broker.id) {
            return;
          }
          return company;
        });

        return broker;
      });

      state.dataByName.users.brokers = updatedBrokers;
    });
  }
});

export const selectStatusByName = (state, name) => state.userList.statusByName[name];
export const selectDataByName = (state, name) => state.userList.dataByName[name];

// Action creators are generated for each case reducer function
export const { clearUserStatus } = userSlice.actions;

export default userSlice.reducer;
