import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchChangeReqByPage = createAsyncThunk(
  'change_requests/fetchChangeReqByPage',
  async ({ page, per_page, query = {} }, { rejectWithValue, getState }) => {
    const params = new URLSearchParams({
      page,
      per_page,
      ...query
    });
    const url = '/api/change-requests?';

    const response = await fetch(url + params, { method: 'GET' });
    if (response.status < 200 || response.status >= 300) {
      rejectWithValue(response.message);
    }

    let body = await response.json();

    if (!body.success) {
      rejectWithValue(body.message);
    }

    return body;
  });

export const updatedRequest = createAsyncThunk(
  'change_requests/update',
  async (object, { rejectWithValue }) => {
    const response = await fetch('/api/change-requests', {
      method: 'PATCH',
      body:   JSON.stringify({ ...object.request })
    });

    if (response.status < 200 || response.status >= 300) {
      rejectWithValue(response.message);
    }

    let body = await response.json();

    if (!body.success) {
      rejectWithValue(body.message);
    }

    return body;
  });

export const createRequest = createAsyncThunk(
  'change_requests/create',
  async (object, { rejectWithValue }) => {
    const response = await fetch('/api/change-requests', {
      method: 'PATCH',
      body:   JSON.stringify({ ...object.request })
    });

    if (response.status < 200 || response.status >= 300) {
      rejectWithValue(response.message);
    }

    let body = await response.json();

    if (!body.success) {
      rejectWithValue(body.message);
    }

    return body;
  });
