import { TextField as MUITextField } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  InputAdornment,
  IconButton,
  Tooltip
} from '@mui/material';
import { useState } from 'react';

function TextField (props) {
  const { mask, forceType, startIcon, endIcon, type = 'text', finalFormProps, readOnly = false, autoComplete = 'off', ...nativeProps } = props;
  const [ toggle, setToggle ] = useState(false);
  const id = `text-field${props.label ? '-' + props.label.replace(/\s+/g, '-').toLowerCase() : ''}`;
  const startAdornment = (startIcon || type === 'password') && <InputAdornment
    sx={{ opacity: nativeProps.disabled ? 0.5 : 1 }}
    position="start">{startIcon || type === 'password' && <PasswordIcon/>}</InputAdornment>;

  return <MUITextField
    id={id}
    margin="normal"
    variant="outlined"
    type={forceType ? type : !toggle && mask ? 'password' : type !== 'password' ? type : 'text'}
    InputLabelProps={type === 'date' ? { shrink: true } : {}}
    InputProps={{
      startAdornment: startAdornment,
      endAdornment:   mask ? <IconButton
        sx={{ opacity: nativeProps.disabled ? 0.5 : 1 }}
        id={`${nativeProps.id ? nativeProps.id : id}-password-toggle`}
        aria-label="toggle password visibility"
        onClick={() => setToggle(!toggle)}
        edge="end">
        <Tooltip
          title={toggle ? 'Hide' : 'Show'}
          arrow
          placement="right-start">
          {toggle ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </Tooltip>
      </IconButton> : endIcon,
      readOnly,
      ...finalFormProps
    }}
    fullWidth
    autoComplete={autoComplete}
    {...nativeProps}
  />;
}

export { TextField as default, TextField };

///////////////////////////////////////////////////////////////////////////////
//!                              USAGE EXAMPLE                               //
///////////////////////////////////////////////////////////////////////////////

// import InfoIcon from '@mui/icons-material/Info';
// import TextField from '../../components/TextField';
//
// export default function UsageExample () {
//   return <TextField
//     mask
//     startIcon={<InfoIcon/>}
//     endIcon={<InfoIcon/> /* mask prop will override this icon */}
//   />;
// }
