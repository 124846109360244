import HandshakeIcon from '@mui/icons-material/Handshake';
import {
  Box,
  Button,
  Container,
  Link,
  Grid,
  Typography
} from '@mui/material';
import { signIn } from 'next-auth/react';
import TextField from '../../components/textField';
import prisma from '../../util/prismaConnect';
import mailer from './api/utils/mailer';

function Copyright (props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {'Copyright © '}
      <Link
        color="inherit"
        href="https://www.associatedemployers.org/">
        Associated Employers
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignInSide (props) {
  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget),
          email = props.email,
          newPassword = data.get('password'),
          oldTempPassword = data.get('tempPassword');

    if (!newPassword) {
      return;
    }

    let response;
    try {
      response = await (await fetch('/api/users', {
        headers: {
          reset_pw: true,
          key:      props.password
        },
        method: 'PUT',
        body:   JSON.stringify({
          email:           email,
          newPassword:     newPassword,
          oldTempPassword: oldTempPassword
        })
      })).json();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.message);
    }

    if (response.success) {
      await signIn('existing', {
        email,
        password:     newPassword,
        passwordPage: true,
        callbackUrl:  '/'
      });
    }
  };

  return (
    <Container className='page-new-password'>
      <Box className="page-new-password centered container shadow">
        <Grid
          component="form"
          onSubmit={handleSubmit}
          spacing={2}
          container>
          <Grid
            className="text-center"
            item
            xs={12}>
            <Typography variant="h3">
              Welcome to AE-Broker
            </Typography>
            <HandshakeIcon sx={{ fontSize: 100 }}/>
          </Grid>
          <Grid
            className="text-center"
            item
            xs={12}>
            <Typography variant="h5">
              Setup Your Password
            </Typography>
          </Grid>

          <Grid
            id="email-field"
            className="text-center"
            item
            xs={12}>
            <TextField
              label="Your Email"
              name="email"
              disabled
              autoFocus
              value={props?.email}>
            </TextField>
          </Grid>

          <Grid
            id="cserror-btn-nav-away"
            className="text-center"
            item
            xs={12}>
            <TextField
              required
              name="tempPassword"
              label="Temporary Password"
              mask
              id="temporary-password"
            />
          </Grid>

          <Grid
            id="cserror-btn-nav-away"
            className="text-center"
            item
            xs={12}>
            <TextField
              required
              name="password"
              label="Password"
              type="password"
              mask
              id="password"
            />
          </Grid>
          <Grid
            id="cserror-btn-nav-away"
            className="text-center"
            item
            xs={12}>
            <Button
              type="submit"
              id="button-set-password"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2
              }}>
              Set Password
            </Button>
          </Grid>
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </Container>
  );
}
export async function getServerSideProps (context) {
  const token = context.query.token,
        email = context.query.email;
  let user;

  if (!token) {
    return {
      redirect: {
        destination: '/login',
        permanent:   false
      }
    };
  }

  if (token && email) {
    let today = new Date(),
        creators = [],
        hasPermPass = today.setDate(today.getDate() + 1);

    user = (await prisma.user.findMany({ where: { email } }))[0];
    if (!user?.createdBy) {
      const admins = await prisma.user.findMany({ where: { role: 'admin' } });
      creators.push(...admins);
    } else {
      const createdBy = await prisma.user.findUnique({ where: { id: user.createdBy } });
      creators.push(createdBy);
    }
    if (user?.password.hash !== token || (user?.password?.expires || hasPermPass) < new Date()) {
      if (user?.password?.expires < new Date()) {
        creators.forEach(creator => {
          mailer({
            to:       creator.email,
            subject:  `${user.name.first}'s link has expired`,
            template: 'activationExpired',
            props:    {
              creator,
              createdUser: user
            },
            text: `${user.firstName} ${user.lastName}'s with a (${user.role}) role, link has expired, please login in to AE-Broker and resend the link.`
          });
        });
      }
    }
  }

  const userRedirect = !token && !email ? '/login' : !user?.password?.expires ? '/login' : false,
        newRoute = userRedirect ||  '/';

  if (userRedirect) {
    return {
      redirect: {
        destination: newRoute,
        permanent:   false
      }
    };
  }

  return {
    props: {
      expires:  !!user?.password?.expires,
      email:    user.email,
      password: user.password.hash,
      role:     user.role
    }
  };
}
