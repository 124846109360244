import { createTheme } from '@mui/material/styles';

const changeRequestFields = {
  id:          true,
  remark:      true,
  approvedOn:  true,
  declinedOn:  true,
  createdOn:   true,
  employeeIds: true,
  companyIds:  true,
  userIds:     true,
  modelName:   true,
  original:    {
    select: {
      id:       true,
      snapshot: true
    }
  },
  conflicting: {
    select: {
      id:       true,
      snapshot: true
    }
  },
  proposed:        true,
  employeeRecords: {
    select: {
      id:             true,
      uid:            true,
      ssn:            true,
      name:           true,
      dob:            true,
      gender:         true,
      email:          true,
      dateOfHire:     true,
      companyRecords: {
        select: {
          name: true,
          ein:  true
        }
      }
    }
  },
  companyRecords: {
    select: {
      id:            true,
      brokerRecord:  { select: { name: true } },
      contactRecord: { select: { name: true } },
      name:          true,
      ein:           true,
      address:       true,
      category:      true
    }
  },
  userRecords: {
    select: {
      id:         true,
      name:       true,
      email:      true,
      role:       true,
      companyIds: true,
      brokerIds:  true
    }
  }
};

// Health Form Questionnaire's can be extended by 30 days
const hfqExtend = [ 30, 'd' ];

const states = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

const genders = [{
  value: 'M',
  label: 'Male'
}, {
  value: 'F',
  label: 'Female'
}];

const coverageTypes = {
  EE:      [ 'Employee Only' ],
  ES:      [ 'Employee & Spouse', 'Employee + Spouse' ],
  Fam:     [ 'Employee & Family', 'Employee + Family' ],
  EC:      [ 'Employee & Children', 'Employee + Child(ren)', 'Employee + Children', 'Employee & Child(ren)', 'Employee + Child', 'Employee & Child' ],
  WO:      [ 'Waived-Other Coverage', 'Other Coverage' ],
  WP:      [ 'Waiting Period' ], // Have a waiting period show on the form if that option is selected. 
  NE:      [ 'Not Eligible' ],
  RC:      [ 'Refusing Coverage' ],
  Cobra:   [ 'Cobra' ],
  Owner:   [ 'Owner' ],
  Retiree: [ 'Retiree' ]
};
const enrolling = Object.keys(coverageTypes).filter(key => ![ 'WO', 'RC', 'NE' ].includes(key));

const authRoutes = {
  broker:   '/broker',
  admin:    '/admin',
  employee: '/employee',
  company:  '/company',
  shared:   '/shared'
};

// Used by NextJS API routes to set size restrictions on the body of the request, 1mb is the default value
const sizeLimit = 8;

const aeTrustPhone = {
  href:      'tel:406-248-6224',
  formatted: '+1 (406) 248-6224'
};

const transport = {
  service: 'SendGrid',
  auth:    {
    user: 'apikey',
    pass: process.env.MAILAPIKEY
  },
  host: process.env.HOST,
  port: process.env.PORTS
};

const roles = {
  broker:   [ authRoutes.broker, authRoutes.company, authRoutes.shared ],
  admin:    [ authRoutes.admin, authRoutes.broker, authRoutes.employee, authRoutes.company, authRoutes.shared ],
  employee: [ authRoutes.employee ],
  company:  [ authRoutes.company, authRoutes.shared ]
};

const theme = createTheme({
  palette: {
    white: {
      main:         '#FFFFFF',
      contrastText: '#FFFFFF'
    },
    'warning-variation': {
      main:         '#b9520b',
      contrastText: '#feffff'
    },
    neutral: {
      main:         '#cfcfcf',
      contrastText: '#000000'
    },
    'light-blue': {
      main:         '#0b9cea',
      contrastText: '#FFFFFF'
    }
  },
  shape: {
    borderRadius: 3,
    borderWidth:  2
  }
});

///////////////////////////////////////////////////////////////////////////////
//!                          PRISMA RELATIONSHIPS                            //
///////////////////////////////////////////////////////////////////////////////

const relationships = {
  employee: {
    companyRecords:         { include: { contactRecord: true } },
    healthFormStatusRecord: {
      select: {
        id:        true,
        signature: true,
        signedOn:  true,
        deleteBy:  true
      }
    },
    changeRequestRecords: {
      include: {
        employeeRecords: true,
        companyRecords:  true,
        userRecords:     true
      }
    }
  },
  company: {
    certificationRecord: true,
    contactRecord:       true,
    brokerRecord:        true,
    employeeRecords:     {
      include: {
        healthFormStatusRecord: {
          select: {
            id:        true,
            signature: true,
            signedOn:  true,
            deleteBy:  true
          }
        },
        changeRequestRecords: {
          include: {
            employeeRecords: true,
            companyRecords:  true,
            userRecords:     true
          }
        }
      }
    }
  },
  user: {
    createdBy: true,
    companies: true
  }
};

const starterTemplates = [{
  file:  '2022-health-assessment.pdf',
  label: 'Health Assessment'
}, {
  file:  'census_template.xlsx',
  label: 'Census Template'
}];

export {
  changeRequestFields,
  starterTemplates,
  aeTrustPhone,
  coverageTypes,
  enrolling,
  sizeLimit,
  genders,
  roles,
  states,
  relationships,
  theme,
  hfqExtend,
  transport
};
