import { createSlice, isFulfilled } from '@reduxjs/toolkit';
import { updatedRequest } from '../actions/changeRequestAction';
import { updateCompany, unlinkEmployee, fetchCompanyByPage, addNewCompany, quoteCompany, updateHFQ, requestQuote } from '../actions/companyAction';
import { addEmployees, updateEmployees } from '../actions/employeeAction';
import { uploadCensus } from '../actions/employeeAction';

export const selectStatusByName = (state, name) =>
  state.companyList.statusByName[name];
export const selectDataByName = (state, name) =>
  state.companyList.dataByName[name];

export const companySlice = createSlice({
  name:         'company',
  initialState: {
    value:        0,
    statusByName: {
      addingCompany: null,
      companyError:  null
    },
    dataByName: { company: { companies: [] } }
  },
  reducers: {
    clearCompanyStatus: (state, action) => {
      state.statusByName.addingCompany = null;
      state.statusByName.companyError = null;
      state.statusByName.QUOTE_COMPANY = null;
    }
  },
  extraReducers: (builder) => {
    let arr = [{
      name: 'COMPANY_PAGINATION_FETCH',
      ...fetchCompanyByPage
    }, {
      name: 'COMPANY_ADDING',
      ...addNewCompany
    },  {
      name: 'COMPANY_UPDATING',
      ...updateCompany
    },  {
      name: 'EMPLOYEE_UNLINK_COMPANY',
      ...unlinkEmployee
    }, {
      name: 'QUOTE_COMPANY',
      ...quoteCompany
    }, {
      name: 'QUOTE_REQUEST_COMPANY',
      ...requestQuote
    }, {
      name: 'UPDATE_COMPANY_HFQ',
      ...updateHFQ
    }];

    arr.forEach(a => {
      builder.addCase(a.pending, (state, action) => {
        state.statusByName[a.name] = 'pending';
        if (action.type === 'company/fetchByPage/pending') {
          state.dataByName.company = {
            allCompanies: [],
            companies:    []
          };
        }
      })
      .addCase(a.fulfilled, (state, action) => {
        state.statusByName[a.name] = 'fulfilled';
        if (action.type === 'company/fetchByPage/fulfilled') {
          state.dataByName.company = action.payload;
        }

        if (action.type === 'company/add/fulfilled') {
          const comp = action.payload.company[0],
                companiesIndex = state.dataByName.company.companies.findIndex(({ id }) => id === comp.id),
                allCompaniesIndex = state.dataByName.company.allCompanies.findIndex(({ id }) => id === comp.id);

          if (companiesIndex >= 0) {
            state.dataByName.company.companies[companiesIndex] = comp;
            state.dataByName.company.allCompanies[allCompaniesIndex] = comp;
          } else {
            state.dataByName.company.companies.push(comp); // adds to the current list page view
            state.dataByName.company.allCompanies.push(comp);
          }

          state.dataByName.company.count = {
            total:      state.dataByName.company.count.total += companiesIndex >= 0 ? 0 : 1,
            completed:  state.dataByName.company.count.completed,
            inProgress: state.dataByName.company.count.inProgress += companiesIndex >= 0 ? 0 : 1
          };
          state.statusByName.addingCompany = true;
          return;
        }
        if (action.type === 'company/update/fulfilled') {
          let companies = state.dataByName.company.companies;

          companies = companies.map(comp => {
            let company = comp;
            if (company.id === action.payload.id) {
              company = action.payload;
            }
            return company;
          });
          state.dataByName.company.companies = companies;
        }
        if (action.type === 'company/quote/fulfilled') {
          let updatedCompanies = state.dataByName.company.companies;

          updatedCompanies = updatedCompanies.map(company => {
            if (company.id === action.payload.company.id) {
              return action.payload.company;
            }
            return company;
          });

          state.dataByName.company.count = action.payload.count;
          state.dataByName.company.companies = updatedCompanies;
          return;
        }
        if (action.type === 'company/requestQuote/fulfilled') {
          let updatedCompanies = state.dataByName.company.companies;
          updatedCompanies = updatedCompanies.map(company => {
            let updatedCompany = company;
            if (company.id === action.payload.id) {
              updatedCompany = action.payload;
            }
            return updatedCompany;
          });

          state.dataByName.company.companies = updatedCompanies;
        }
        if (action.type === 'company/updateHFQ/fulfilled') {
          let updatedCompanies = state.dataByName.company.companies;

          updatedCompanies = updatedCompanies.map(company => {
            let updatedCompany = company;
            if (company.id === action.payload.id) {
              updatedCompany = action.payload;
            }
            return updatedCompany;
          });

          state.dataByName.company.companies = updatedCompanies;
        }
        if (action.type === 'employee/unlinkFromComp/fulfilled') {
          const companies = state.dataByName.company.companies;
          let count = state.dataByName.company.count;
          const updatedCompanies = companies.map(comp => {
            let company = comp;
            if ((company || {}).id === action.payload?.id) {
              company = action.payload;
            }
            return company;
          });

          const completed = updatedCompanies.filter(({ status }) => status).length;
          const inProgress = (count?.total || 0) - completed;
          count = {
            total: count?.total || 0,
            completed,
            inProgress
          };

          state.dataByName.company.count = count;
          state.dataByName.company.companies = updatedCompanies;
        }
      })
      .addCase(a.rejected, (state, action) => {
        state.statusByName[a.name] = 'rejected';
        if (action.type === 'company/add/rejected') {
          state.statusByName.companyError = action.payload;
        }
      });
    });

    // Updates the employees in <ListItems /> when employees are added
    builder.addMatcher(isFulfilled(addEmployees), (state, action) => {
      let companies = state.dataByName.company.companies;
      let allCompanies = state.dataByName.company.allCompanies || [];

      allCompanies = allCompanies.map(company => {
        let matching = action.payload.filter(emp => emp.companyIds[0] === company.id)[0];
        if (matching) {
          company.employeeRecords.push(...action.payload);
          company.employeeIds.push(action.payload[0].id);
          company.steps = action.payload[0].companyRecords[0].steps;
        }

        company.status = company.employeeRecords?.length ? !company.employeeRecords.some(({ status }) => !status) : false;

        return company;
      });

      companies = companies.map(company => {
        let matching = action.payload.filter(emp => emp.companyIds[0] === company.id)[0];
        if (matching) {
          company.employeeRecords.push(...action.payload);
          company.employeeIds.push(action.payload[0].id);
          company.steps = action.payload[0].companyRecords[0].steps;
        }

        company.status = company.employeeRecords?.length ? !company.employeeRecords.some(({ status }) => !status) : false;

        return company;
      });

      // Updates the count in the companies view on the administrative & broker screen
      let count = state.dataByName.company.count;
      const completed = companies.filter(({ status }) => status).length;
      const inProgress = (count?.total || 0) - completed;
      count = {
        total: count?.total || 0,
        completed,
        inProgress
      };

      state.dataByName.company.count = count;
      state.dataByName.company.companies = companies;
      state.dataByName.company.allCompanies = allCompanies;
    });

    // Updates the employees in <ListItems /> when employees are uploaded via census
    builder.addMatcher(isFulfilled(uploadCensus), (state, action) => {
      const employees = action.payload.employees;
      const company   = action.payload.company;
      const addEmployeeStep = company.steps.find(({ label }) => label === 'Add Employees');
      const companyId = (employees || [])[0]?.companyIds[0];

      if (companyId) {
        state.dataByName.company.companies.map(comp => {
          if (comp.id === companyId) {
            const updatedEmployeeRecords = [ ...comp.employeeRecords, ...employees ];
            const updatedEmployeeIds = [ ...comp.employeeIds, ...(employees || []).map(({ id }) => id) ];
            const updatedCompanySteps = comp.steps.map(step => {
              if (step.label === 'Add Employees') {
                return {
                  ...addEmployeeStep,
                  userId: step.userId,
                  ...addEmployeeStep.resetBy ? { resetBy: { ...step.resetBy } } : {}

                };
              }
              return step;
            });

            comp.steps = updatedCompanySteps;
            comp.documentsUploaded = company.documentsUploaded;
            comp.employeeRecords = updatedEmployeeRecords;
            comp.employeeIds = updatedEmployeeIds;
          }

          return company;
        });
      }
    });

    // Updates the employee in <ListItems /> when a change request is created
    builder.addMatcher(isFulfilled(updateEmployees), (state, action) => {
      const companyIds = [ ...action.payload.emp[0].companyIds, ...(action.payload || [])[1]?.companyIds || [] ];

      if (companyIds) {
        state.dataByName.company.companies.map(company => {
          if (companyIds.includes(company.id)) {
            const updatedCompany = company;
            const updatedEmployeeRecords = company.employeeRecords.map(employee => {
              const updatedEmployee = action.payload.emp.find(record => record.id === employee.id);
              if (updatedEmployee) {
                return updatedEmployee;
              }
              return employee;
            });

            updatedCompany.employeeRecords = updatedEmployeeRecords;
            return updatedCompany;
          }
          return company;
        });
      }
    });

    // Updates the employee in <ListItems /> when a change request is approved / declined
    builder.addMatcher(isFulfilled(updatedRequest), (state, action) => {
      const changeRequest = action.payload.updatedRequest;
      const companyIds = [ ...changeRequest.employeeRecords[0].companyIds, ...changeRequest.employeeRecords[1].companyIds ];
      if (companyIds) {
        state.dataByName.company.companies.map(company => {
          if (companyIds.includes(company.id)) {
            const updatedCompany = company;
            const updatedEmployeeRecords = company.employeeRecords.map(employee => {
              const updatedEmployee = action.payload.updatedRecords.find(record => record.id.toString() === employee.id.toString());

              if (updatedEmployee) {
                if (!updatedEmployee?.companyIds?.length) {
                  return;
                }
                return updatedEmployee;
              }
              return employee;
            });

            updatedCompany.employeeRecords = updatedEmployeeRecords.filter(empRec=> !!empRec);
            return updatedCompany;
          }
        });
      }
    });
  }
});

// Action creators are generated for each case reducer function
export const { clearCompanyStatus } = companySlice.actions;

export default companySlice.reducer;
