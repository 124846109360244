/* eslint-disable no-console */

import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createWrapper } from 'next-redux-wrapper';
import rootReducer from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

const initialState = {
  changeReqList: {
    statusByName: {},
    dataByName:   {
      changeRequests: {
        changeRequests: {
          employees: [],
          companies: [],
          users:     []
        },
        allChangeRequests: []
      }
    }
  },
  employeeList: {
    dataByName: {
      companyEmployees: {
        employees:    [],
        allEmployees: []
      }
    },
    statusByName: {
      addingEmployees:   null,
      EMPLOYEE_UPDATING: null
    }
  },
  companyList: {
    dataByName: {
      company: {
        companies:    [],
        allCompanies: []
      }
    },
    statusByName: {
      companyError:  null,
      addingCompany: null
    }
  }
};

//start of redux logger
const actionTransformer = action => {
  if (action.type === 'BATCHING_REDUCER.BATCH') {
    action.payload.type = action.payload.map(next => next.type).join(' => ');
    return action.payload;
  }

  return action;
};
const level = 'info';

const logger = {};
for (const method in console) {
  if (typeof console[method] === 'function') {
    logger[method] = console[method].bind(console);
  }
}

logger[level] = function levelFn (...args) {
  const lastArg = args.pop();

  if (Array.isArray(lastArg)) {
    return lastArg.forEach(item => {
      console[level].apply(console, [ ...args, item ]);
    });
  }

  console[level].apply(console, arguments);
};

const middlewares = [ thunk ];

const loggers = createLogger({
  level,
  actionTransformer,
  logger
});

if (process.env.NODE_ENV === 'development' && !!process.env.SHOW_REDUX_LOGS) {
  middlewares.push(loggers);
}

//end of redux logger
// creating store
export const store = configureStore({
  reducer:        rootReducer,
  preloadedState: initialState,
  devTools:       process.env.NODE_ENV !== 'production' && composeWithDevTools(applyMiddleware(...middlewares)),
  middleware:     middlewares
});

// assigning store to next wrapper
const makeStore = () => store;

export const wrapper = createWrapper(makeStore);
