import { createSlice, isFulfilled } from '@reduxjs/toolkit';
import { fetchChangeReqByPage, updatedRequest } from '../actions/changeRequestAction';
import { updateEmployees } from '../actions/employeeAction';
import pluralize from 'pluralize';

export const changeRequestSlice = createSlice({
  name:         'changerequests',
  initialState: {
    value:        0,
    statusByName: { changeRequestErr: false },
    dataByName:   {
      changeRequests: {
        employees: [],
        companies: [],
        users:     []
      },
      allChangeRequests: []
    }
  },
  reducers: {
    clearChangeReqStatus: (state, action) => {
      state.statusByName.changeRequestErr = false;
    }
  },
  extraReducers: builder => {
    const statuses = [{
      name: 'CH-REQ_PAGINATION_FETCH',
      ...fetchChangeReqByPage
    }, {
      name: 'CH-REQ_UPDATE',
      ...updatedRequest
    }
    ];

    statuses.forEach(status => {
      builder.addCase(status.pending, (state, action) => {
        state.statusByName[status.name] = 'pending';
      })
      .addCase(status.fulfilled, (state, action) => {
        state.statusByName[status.name] = 'fulfilled';
        if (action.type === 'change_requests/fetchChangeReqByPage/fulfilled') {
          const { changeRequestCount, total_pages, page, ...payload } = action.payload;
          state.dataByName.changeRequests = payload;
          state.statusByName.page = action.payload.page;
          state.statusByName.changeRequestCount = changeRequestCount;
          state.statusByName.unresolved = payload.allChangeRequests.filter(cr => !cr.approvedOn && !cr.declinedOn).length;
          state.statusByName.page = page;
          state.statusByName.total_pages = total_pages;
          return;
        }
        if (action.type === 'change_requests/update/fulfilled') {
          const updateStateEmployees = records => (records || []).map(rec => {
            let currentRec = rec;

            if (currentRec?.id === action.payload?.updatedRequest?.id) {
              currentRec = action.payload.updatedRequest;
            } else if ((currentRec.id || '').toString() === (action.payload?.updatedRequest?.id || '').toString()) {
              currentRec = action.payload.updatedRequest;
            }
            return currentRec;
          });

          //TODO: Hardcoded
          state.dataByName.changeRequests.changeRequests.employees = updateStateEmployees(state.dataByName.changeRequests.changeRequests.employees);
        }
      })
      .addCase(status.rejected, (state, action) => {
        state.statusByName[status.name] = 'rejected';
        if (action.type === 'changerequests/fetchChangeReqByPage/rejected') {
          state.statusByName.changeRequestErr = action.payload;
        }
      });
    });

    // Adds the new change request whenever an employee is edited
    builder.addMatcher(isFulfilled(updateEmployees), (state, action) => {
      const updatedCR = action.payload.changerequest;
      const { changeRequests, allChangeRequests } = state.dataByName.changeRequests;

      const per_page = 10;
      if (updatedCR) {
        const model = pluralize(updatedCR.modelName);
        if (!changeRequests.length || changeRequests.length < per_page) {
          changeRequests[model].push(updatedCR);
        }
        allChangeRequests.push(updatedCR);
      }
    });
  }
});

export const changeReqStatus = (state, name) => state.changeReqList.statusByName[name];
export const changeReqData = (state, name) => state.changeReqList.dataByName[name];

// Action creators are generated for each case reducer function
export const { clearChangeReqStatus } = changeRequestSlice.actions;

export default changeRequestSlice.reducer;
