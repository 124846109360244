import { createSlice } from '@reduxjs/toolkit';
import { updateCertification } from '../actions/certificationAction';
import { fetchCertifications } from '../actions/certificationAction';

export const getCertStatus = (state, name) =>
  state.certList.status[name];
export const getCertData = (state, name) =>
  state.certList.data[name];

export const certSlice = createSlice({
  name:         'certification',
  initialState: {
    value:  0,
    status: { updateCert: null },
    data:   { certifications: [] }
  },
  reducers: {
    clearCertStatus: (state, action) => {
      state.status.certError = null;
    }
  },
  extraReducers: (builder) => {
    let reducerActions = [{
      name: 'CERTIFICATION_FETCH',
      ...fetchCertifications
    }, {
      name: 'CERTIFICATION_UPDATE',
      ...updateCertification
    }];

    reducerActions.forEach(reducer => {
      builder.addCase(reducer.pending, (state, action) => {
        state.status[reducer.name] = 'pending';
      })
      .addCase(reducer.fulfilled, (state, action) => {
        state.status[reducer.name] = 'fulfilled';
        if (action.type === 'certification/get/fulfilled') {
          state.data = action.payload;
        }

        if (action.type === 'certification/update/fulfilled') {
          let updatedAllCerts = state.data.allCerts;
          let updatedPaginatedCerts = state.data.paginatedCerts;

          updatedPaginatedCerts = updatedPaginatedCerts.map(cert => {
            if (cert.id === action.payload.id) {
              return {
                brokerName:  cert.brokerName,
                companyName: cert.companyName,
                ...action.payload
              };
            }
            return cert;
          });

          updatedAllCerts = updatedAllCerts.map(cert => {
            if (cert.id === action.payload.id) {
              return {
                brokerName:  cert.brokerName,
                companyName: cert.companyName,
                ...action.payload
              };
            }
            return cert;
          });

          state.data.paginatedCerts = updatedPaginatedCerts;
          state.data.allCerts = updatedAllCerts;
        }
      })
      .addCase(reducer.rejected, (state, action) => {
        state.status[reducer.name] = 'rejected';
        if (action.type === 'certification/update/rejected') {
          state.status.companyError = action.payload;
        }
      });
    });
  }
});

// Action creators are generated for each case reducer function
export const { clearCertStatus } = certSlice.actions;

export default certSlice.reducer;
