/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSession, signOut, signIn, SessionProvider } from 'next-auth/react';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import { roles, theme } from '../../config/statics.js';
import { Footer } from '../../components/footer';
import '../../styles/globals.scss';
import NewPassword from './new-password';
import '../../styles/globals.scss';
import Head from 'next/head';
import { wrapper } from '../../store/store';
import { Provider } from 'react-redux';
import { setCurrentView } from '../../store/actions/viewChangerAction';
import { getConfig } from '../../store/actions/configAction';
import { useDispatch, useSelector } from 'react-redux';

function MyApp ({ Component, ...rest }) {
  const { store, props } = wrapper.useWrappedStore(rest);
  const {  pageProps, session } = props;

  return (
    <SessionProvider
      session={session}
      refetchInterval={60 * 60}>
      <Head><title>AE Broker</title></Head>
      <AuthHandler>
        <Provider
          store={store}
          session={session}>
          <ProviderCaller
            store={store}>
            <ThemeProvider theme={theme}>
              <Box
                onDrop={event => event.preventDefault()}
                onDragOver={event => event.preventDefault()}>
                <Component {...pageProps} />
              </Box>
              <Footer />
            </ThemeProvider>
          </ProviderCaller>
        </Provider>
      </AuthHandler>
    </SessionProvider>
  );
}

function ProviderCaller ({ children, store }) {
  const router = useRouter();
  const dispatch = useDispatch();

  const { data: session } = useSession();
  let { configs  } = useSelector(state => state.configList);
  const brokers = useSelector(state => state.userList?.dataByName?.users?.brokers);
  let { view } = useSelector(state => state.currentView);

  useEffect(() => {
    if (!!session?.user && !configs.role || session?.user && (router.query.broker || router.query.company)) {
      dispatch(getConfig({
        query: router.query,
        session
      }));
    }
  }, [ session?.user, router.query.company, router.query.broker, brokers ]);

  useEffect(() => {
    if (Object.keys(router.query).length > 0 && !router.query?.slug) { //mapping the route params to the correct view
      let map = {
        'broker':  'companies',
        'company': 'companies'
      };
      let nextView = map[Object.keys(router.query)[0]];
      if (nextView !== view) {
        dispatch(setCurrentView(nextView));
      }
    } else if (configs?.role) {
      let nextView = configs?.role === 'broker' ? 'companies' : configs?.role === 'company' ? 'employees' : 'brokers';
      if (nextView !== view) {
        dispatch(setCurrentView(nextView));
      }
    }
  }, [ configs?.role, router.query?.slug, router.query?.broker, router.query?.company ]);

  if (window.Cypress) {
    window.store = store;
  }

  return children;
}

function AuthHandler ({ children }) {
  const router = useRouter();
  const { status, data: session } = useSession();
  let allowedRoles = roles[session?.user?.role] || [];
  let blocked = allowedRoles.filter(role => router.pathname.includes(`auth${role}`)).length === 0 && router.pathname.includes('/auth');

  // Automatically logs the user out when their session expires
  useEffect(() => {
    if (session?.expired) {
      signOut();
    }
  }, [ session ]);

  // TODO: Add a loading screen
  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (session?.newPassword) {
    return <NewPassword />;
  }

  if (router.pathname.includes('auth/employee/') && blocked) {
    router.push('/auth/employee');
    return;
  }

  if (router.pathname.endsWith('employee')) {
    return children;
  }

  if ((blocked || !allowedRoles.length) && session) {
    router.replace('/unauthorized');
    return;
  } else if (blocked && !session) {
    signIn();
    return;
  }

  return children;
}

export default MyApp;
