import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchUsersByPage = createAsyncThunk(
  'user/fetchByPage',
  async ({ page, query, per_page, session = {} }, { rejectWithValue, getState }) => {
    const state = await getState(),
          isAdmin = await state?.configList?.configs?.isAdmin || session?.user?.role === 'admin',
          role = await state?.configList?.configs?.role || session?.user?.role,
          user = await state?.configList?.configs || session?.user,
          csrsQuery = await state?.currentView.view === 'Customer Service Reps',
          userID = await state?.configList?.configs?.userId || session?.user?.id;

    if (!userID) {
      return rejectWithValue('NO USER ID');
    }

    let brokerQuery = isAdmin ? {
      role: 'broker',
      OR:   [{ companyIds: { isEmpty: false } }, { companyIds: { isEmpty: true } }]
    } : { id: { in: session.brokerIds  } };

    let csrQuery = isAdmin ? {
      role: 'broker',
      OR:   [{ brokerIds: { isEmpty: false } }, { brokerIds: { isEmpty: false } }]
    } : { id: { in: session.brokerIds  } };

    const url = '/api/users?';
    const params = new URLSearchParams({
      isAdmin,
      page,
      per_page,
      rel:     [ 'brokerCompanies' ],
      where:   JSON.stringify(csrsQuery ? csrQuery : brokerQuery),
      include: JSON.stringify({
        brokerRecords: true,
        companyIds:    true
      })
    });

    const res = await fetch(url + params, {
      method:  'GET',
      headers: { force: true }
    });
    const body = await res.json();

    if (res.status < 200 || res.status >= 300) {
      return rejectWithValue(res.error);
    }

    let users = body.user.filter(broker=>!(broker.brokerIds || []).length && broker.role === 'broker');
    let AllCsrs = body.allUsers.filter(broker=>broker?.brokerIds?.length  && broker.role === 'broker');
    let AllBrokers = body.allUsers.filter(broker=>broker?.companyIds.length >= 0 && !(broker.brokerIds || []).length && broker.role === 'broker');
    let csrsWithBrokers = (csrsQuery ? body.user : AllCsrs).map(csr=>{
      if (csr.brokerIds?.length) {
        let brokers = AllBrokers.filter(broker=>csr.brokerIds.includes(broker.id));
        return {
          brokers,
          ...csr
        };
      }
    });

    return {
      'csrCount': role === 'broker' ? AllCsrs.filter(csr=>{
        return csr.brokerIds.includes(user.userId);
      }).length : AllCsrs.length,
      'brokerCount':  AllBrokers.length,
      'allUserCount': body.allUsers.length,
      'brokers':      [ ...users ],
      'csrs':         [ ...csrsWithBrokers ],
      'allUsers':     [ ...body.allUsers ],
      'allBrokers':   isAdmin ? AllBrokers : users
    };
  }
);

export const addUser = createAsyncThunk('user/add',
  async ({ user, role }, { rejectWithValue, getState }) => {
    const res = await fetch('/api/users', {
      method: 'POST',
      body:   JSON.stringify(user)
    });
    const body = await res.json();


    if (res.status < 200 || res.status >= 300) {
      return rejectWithValue(res);
    }

    return { [role]: body.user };
  });

export const unlinkBrokerFromCSR = createAsyncThunk(
  'broker/unlinkFromCSR',
  async ({ broker, csr: linkedCSR  }, { rejectWithValue, getState }) => {
    const state = await getState();
    let csrs = [ ...state.userList.dataByName.users.csrs ];
    let brokerId = broker.id ? broker.id : broker;

    const updatedLinkedCSR = {
      ...linkedCSR,
      brokerIds: linkedCSR.brokerIds.filter(id => id !== brokerId),
      brokers:   linkedCSR.brokers.filter(({ id }) => id !== brokerId)
    };
    const removeCSR = !updatedLinkedCSR.brokers.length;

    const res = await fetch('/api/users', {
      method: removeCSR ? 'DELETE' : 'PUT',
      body:   JSON.stringify({ ...updatedLinkedCSR })
    });
    const body = await res.json();

    if (res.status < 200 || res.status >= 300) {
      return rejectWithValue(res);
    }

    body.user = {
      ...body.user,
      brokers: updatedLinkedCSR.brokers
    };

    let updated = csrs.reduce((updatedCSRs, csr) => {
      if (csr.id === linkedCSR.id && !removeCSR) {
        updatedCSRs.push(body.user);
      }
      if (body.user.id !== csr.id) {
        updatedCSRs.push(csr);
      }
      return updatedCSRs;
    }, []);

    return {
      csrs:    updated,
      message: 'Broker unlinked from CSR',
      success: true
    };
  }
);
