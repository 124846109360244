import { createSlice } from '@reduxjs/toolkit';
import { updateEmployees, addEmployees, fetchEmployeesByPage, uploadCensus } from '../actions/employeeAction';
import { updatedRequest } from '../actions/changeRequestAction';
import { unlinkEmployee } from '../actions/companyAction';
import { isFulfilled } from '@reduxjs/toolkit';
import { updateHFQ } from '../actions/companyAction';

export const employeeSlice = createSlice({
  name:         'employees',
  initialState: {},
  reducers:     {
    clearEmpStatuses: (state, action) => {
      if (action.payload === 'Employee') {
        state.statusByName = {
          ...state.statusByName,
          EMPLOYEE_ADDING:   false,
          empUpdatedErrMsg:  false,
          EMPLOYEE_UPDATING: false
        };
      }
      if (action.payload === 'Census') {
        state.statusByName = {
          ...state.statusByName,
          censusErrMsg:     false,
          censusSuccessMsg: false,
          censusWarningMsg: false,
          CENSUS_UPLOAD:    false
        };
      }
    }
  },
  extraReducers: (builder) => {
    let arr = [{
      name: 'EMPLOYEE_UPDATING',
      ...updateEmployees
    }, {
      name: 'EMPLOYEE_PAGINATION_FETCH',
      ...fetchEmployeesByPage
    }, {
      name: 'EMPLOYEE_ADDING',
      ...addEmployees
    }, {
      name: 'CENSUS_UPLOAD',
      ...uploadCensus
    }];

    arr.forEach(a => {
      builder.addCase(a.pending, (state, action) => {
        state.statusByName[a.name] = 'pending';
      })
      .addCase(a.fulfilled, (state, action) => {
        let page = action.meta.arg.page;
        state.statusByName[a.name] = 'fulfilled';
        state.statusByName.page = page;
        if (action.type === 'employee/adding/fulfilled') {
          state.dataByName.companyEmployees.employees.push(...action.payload);
          state.dataByName.companyEmployees.allEmployees.push(...action.payload);

          let count = state.dataByName.companyEmployees.count;
          if (count) {
            count = {
              total:      count.total + 1,
              inProgress: !action.payload[0].status ? count.inProgress + 1 : count.inProgress,
              completed:  action.payload[0].status ? count.completed + 1 : count.completed
            };

            state.dataByName.companyEmployees.count = count;
          }

        }
        if (action.type ===  'employee/census/fulfilled') {
          state.statusByName.censusSuccessMsg = action.payload.message;
          state.statusByName.censusWarningMsg = action.payload.warning;
          state.dataByName.companyEmployees.employees.push(...action.payload.employees);
          state.dataByName.companyEmployees.allEmployees.push(...action.payload.employees);
        }
        if (action.type === 'employee/update/fulfilled') {
          const companyEmployees = state.dataByName.companyEmployees;
          companyEmployees.employees = companyEmployees.employees.map(emp => {
            let matchingRec = action.payload.emp.filter(rec => (rec.id || '').toString() === (emp.id || '').toString())[0];
            if (matchingRec) {
              return matchingRec;
            }
            return emp;
          });

          companyEmployees.allEmployees = companyEmployees.allEmployees.map(emp => {
            let matchingRec = action.payload.emp.filter(rec => rec.id.toString() === emp.id.toString())[0];

            if (matchingRec) {
              return matchingRec;
            }
            return emp;
          });

          state.dataByName.companyEmployees = companyEmployees;
        }
        if (action.type === 'employee/fetchByPage/fulfilled') {
          state.dataByName.companyEmployees = action.payload;
        }
      })
      .addCase(a.rejected, (state, action) => {
        state.statusByName[a.name] = 'rejected';
        if (action.type ===  'employee/census/rejected') {
          state.statusByName.censusErrMsg = action.payload;
        }
        if (action.type === 'employee/update/rejected') {
          state.statusByName.empUpdatedErrMsg = action.payload;
        }
      });
    });

    builder.addMatcher(isFulfilled(unlinkEmployee), (state, action) => {
      const updatedCompanyEmployees = state.dataByName.companyEmployees;
      let count = state.dataByName.companyEmployees.count;
      updatedCompanyEmployees.employees = updatedCompanyEmployees.employees.filter(emp => {
        if (emp.id === action.meta.arg.employeeId) {
          return;
        }
        return emp;
      });

      updatedCompanyEmployees.allEmployees = updatedCompanyEmployees.allEmployees.map(emp => {
        if (emp.id === action.meta.arg.employeeId) {
          emp.companyIds = (emp.companyIds || []).filter(id => id !== action.meta.arg.companyId);
          emp.companyRecords = (emp.companyEmployees || []).filter(({ id }) => id !== action.meta.arg.companyId);
          return emp;
        }
        return emp;
      });

      updatedCompanyEmployees.allCompUserEmps &&= updatedCompanyEmployees.allCompUserEmps.filter(emp => {
        if (emp.id === action.meta.arg.employeeId) {
          return;
        }
        return emp;
      });

      // Updates the count in the employees view of a company
      if (count) {
        updatedCompanyEmployees.count = {
          total:      count.total - 1,
          inProgress: updatedCompanyEmployees.allCompUserEmps.filter(({ status }) => !status).length,
          completed:  updatedCompanyEmployees.allCompUserEmps.filter(({ status }) => status).length
        };
      }

      state.dataByName.companyEmployees = updatedCompanyEmployees;
    });

    builder.addMatcher(isFulfilled(updatedRequest), (state, action) => {
      const companyEmployees = state.dataByName.companyEmployees;
      companyEmployees.employees = companyEmployees.employees.map(emp => {
        let matchingRec = action.payload.updatedRecords.filter(rec => rec.id.toString() === emp.id.toString())[0];
        if (matchingRec) {
          return matchingRec;
        }
        return emp;
      });

      companyEmployees.allEmployees = companyEmployees.allEmployees.map(emp => {
        let matchingRec = action.payload.updatedRecords.filter(rec => rec.id.toString() === emp.id.toString())[0];

        if (matchingRec) {
          return matchingRec;
        }
        return emp;
      });

      state.dataByName.companyEmployees = companyEmployees;
    });

    builder.addMatcher(isFulfilled(updateHFQ), (state, action) => {
      const employee = (action.payload.employeeRecords || []).find(({ id }) => id === action.meta?.arg.employee?.id);
      let updatedCount = state.dataByName.companyEmployees.count;
      let updatedCompEmps = state.dataByName.companyEmployees.employees;
      let updatedAllCompUserEmps = state.dataByName.companyEmployees.allCompUserEmps;

      updatedCompEmps = (updatedCompEmps || []).map(emp => {
        if (emp.id === employee.id) {
          return employee;
        }
        return emp;
      });

      updatedAllCompUserEmps = (updatedAllCompUserEmps || []).map(emp => {
        if (emp.id === employee.id) {
          return employee;
        }
        return emp;
      });

      if (updatedCount) {
        updatedCount = {
          completed:  updatedCount.completed - 1,
          total:      updatedCount.total,
          inProgress: updatedCount.inProgress + 1
        };
        state.dataByName.companyEmployees.count = updatedCount;
      }
      state.dataByName.companyEmployees.employees = updatedCompEmps;
      state.dataByName.companyEmployees.allCompUserEmps = updatedAllCompUserEmps;
    });
  }
});



export const selectEmpStatusByName = (state, name) => (state.employeeList.statusByName || {})[name] || false;
export const selectEmpDataByName = (state, name) => (state.employeeList.dataByName || {})[name] || {};

// Action creators are generated for each case reducer function
export const { clearEmpStatuses } = employeeSlice.actions;

export default employeeSlice.reducer;
