
import { SET_CONFIG, GET_CONFIG, CONFIG_ERROR } from '../types';

const initialState = {
  configs: {
    companyQuery: false,
    brokerQuery:  false,
    role:         null,  //broker, company, admin
    title:        '',    // csr, companyUser, broker, admin, changeReqs
    loading:      true,
    isAdmin:      false
  }
};

export default function config (state = initialState, action) {

  switch (action.type) {
    case GET_CONFIG:
      return {
        ...state,
        configs: action.payload,
        loading: false
      };
    case SET_CONFIG:
      return {
        ...state,
        brokerView: action.payload,
        loading:    false
      };
    case CONFIG_ERROR:
      return {
        loading: false,
        error:   action.payload
      };
    default: return state;
  }
}
