import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchCertifications = createAsyncThunk(
  'certification/get',
  async ({ page, per_page } = {}, { rejectWithValue, getState }) => {
    const state = await getState();

    // Get the certifications belonging only to that company user
    const certsURL = new Request('/api/certs?' + new URLSearchParams({ companyContact: state.configList.configs.userId }), { method: 'GET' });
    const { certs } = await (await fetch(certsURL)).json();
    const certifications = certs.map(({ companyRecord, ...certification }) => {
      if (companyRecord?.length) {
        const { name: companyName, brokerRecord: { name: brokerName } } = companyRecord.pop();
        return {
          ...certification,
          companyName,
          brokerName
        };
      }
    });

    // eslint-disable-next-line eqeqeq
    const unapprovedCerts = certifications.filter(cert => !cert?.signature && cert?.revoked == null);
    const revokedCerts = certifications.filter(cert => cert?.revoked);
    const approvedCerts = certifications.filter(cert => cert?.signature && !cert?.revoked);
    const sortedCertifications = [ ...unapprovedCerts, ...approvedCerts, ...revokedCerts ];

    const start = (page - 1) * per_page;
    const end = start + per_page;

    return {
      allCerts:       certifications,
      paginatedCerts: sortedCertifications.slice(start, end)
    };
  });

export const updateCertification = createAsyncThunk(
  'certification/update',
  async (cert) => {
    const res = await (await fetch('/api/certs', {
      method: 'PATCH',
      body:   JSON.stringify(cert)
    })).json();

    return res.certification;
  });
