import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchEmployeesByPage = createAsyncThunk(
  'employee/fetchByPage',
  async ({ page, query, per_page }, { rejectWithValue, getState }) => {
    const state = await getState(),
          contactId = await state.configList?.configs?.title === 'companyUser' ? await state.configList?.configs?.userId : false,
          searchParams = {
            page,
            per_page,
            ...contactId ? { contactId } : {},
            ...query.id ? { id: query.id } : {}
          };

    const empURL = '/api/employees?',
          params = new URLSearchParams(searchParams),
          queriedCompany = !!contactId || !!query.id;

    const responseWithParam = queriedCompany && await (await fetch(empURL + params, { method: 'GET' })).json(),
          response = await (await fetch(empURL, { method: 'GET' })).json();

    return {
      count:           queriedCompany ? responseWithParam.count : response.count,
      page:            queriedCompany ? responseWithParam.page : response.page,
      employees:       queriedCompany ? responseWithParam.employees : [],
      allEmployees:    response.employees || [],
      allCompUserEmps: queriedCompany ? responseWithParam.allCompUserEmps : response.allCompUserEmps
    };
  }
);

export const addEmployees = createAsyncThunk(
  'employee/adding',
  async (object, { rejectWithValue, getState }) => {
    const response = await fetch('/api/employees', {
      method: 'POST',
      body:   JSON.stringify(object)
    });
    const body = await response.json();

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(response.message);
    }

    const company = body.employees[0].companyRecords[0];
    body.employees[0].companyName = [ company.name ];

    return [ body.employees[0] ];
  });

export const updateEmployees = createAsyncThunk(
  'employee/update',
  async (data, { rejectWithValue, getState }) => {
    const response = await fetch('/api/employees', {
      method: 'PATCH',
      body:   JSON.stringify(data)
    });

    const body = await response.json();

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(body.message); //TODO:: make better error will set the payload
    }

    let emp;
    if (body.employees) {
      emp = body.employees;
    }

    if (body.employee) {
      emp = [ body.employee ];
    }
    return {
      emp:           emp || [],
      changerequest: body.changerequest
    };
  });

export const uploadCensus = createAsyncThunk(
  'employee/census',
  async ({ object }, { rejectWithValue, getState }) => {
    const response = await fetch('/api/employees', {
      method:  'POST',
      headers: { middleware: 'censusUpload' },
      body:    JSON.stringify(object)
    });
    const body = await response.json();

    if (body.message || response.status < 200 || response.status >= 300) {
      let message = response.status < 200 || response.status >= 300 ? response.error : body.message;
      return rejectWithValue(message);
    }

    let message = <ul>
      <li>{body.employees.length} created</li>
      <li>{body.updatedEmployees.length} updated</li>
      <li>{body.duplicateEmployees.length} duplicates</li>
      <li>{body.failedEmployees.length} failed</li>
    </ul>;

    return {
      employees: body.employees,
      company:   body.company,
      warning:   !!body.failedEmployees.length || !!body.duplicateEmployees.length,
      message
    };
  });
