
import { SET_VIEW, VIEW_ERROR } from '../types';

const initialState = {
  view:    '',
  loading: true
};

export default function viewChanger (state = initialState, action) {
  switch (action.type) {
    case SET_VIEW:
      return {
        ...state,
        view:    action.payload.view,
        results: action.payload.results,
        pages:   action.payload.pages,
        loading: false
      };
    case VIEW_ERROR:
      return {
        loading: false,
        error:   action.payload
      };
    default: return state;
  }
}

export const viewChangerStatus = (state, name) => state.currentView.status;
export const viewChangerData = (state, name) => state.currentView[name];
