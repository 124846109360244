import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSession } from 'next-auth/react';
import { Container, Box, Button, Typography } from '@mui/material';
import { aeTrustPhone } from '../config/statics';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import getConfig from 'next/config';

function Footer () {
  const { publicRuntimeConfig } = getConfig();
  const theme = useTheme();
  const session = useSession();
  const [ showCommit, setShowCommit ] = useState();
  const smallIcon = {
    width:  '10px',
    height: '10px'
  };

  const handleShowCommit = () => setShowCommit(prev => !prev);

  return (
    <Box
      onDrop={event => event.preventDefault()}
      onDragOver={event => event.preventDefault()}
      id="footer"
      sx={{
        width:           '100%',
        padding:         '1em',
        textAlign:       'center',
        backgroundColor: '#f7f2f2',
        color:           theme.palette.common.black
      }}>
      <Typography
        id="copyright"
        fontSize={12}>
        &#169; {new Date().getFullYear()} Associated Employers Group Benefit Plan & Trust. All Rights Reserved
      </Typography>
      {
        session.status === 'authenticated' &&
        <>
          <Typography
            id="support"
            fontSize={12}>
            For support please contact us at <a href={aeTrustPhone.href}>{aeTrustPhone.formatted}</a>
          </Typography>
          <Container onClick={handleShowCommit}>
            <Button
              id="button-show-version-info"
              disableRipple
              color="info"
              sx={{ fontSize: '10px' }}
              endIcon={
                showCommit ?
                  <VisibilityOffIcon sx={{ ...smallIcon }} />
                  :
                  <VisibilityIcon sx={{ ...smallIcon }} />
              }
              display="inline"
              fontSize={10}>
              Show Version Info
            </Button>
            {
              showCommit &&
              <Typography
                id="version-info"
                fontSize={10}>
                {publicRuntimeConfig?.version || 'N/A'}
              </Typography>
            }
          </Container>
        </>
      }
    </Box>
  );
}

export { Footer as default, Footer };
