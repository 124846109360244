export const GET_COMPANIES = 'GET_COMPANIES';
export const COMPANY_ERROR = 'COMPANY_ERROR';
export const ADD_COMPANIES = 'ADD_COMPANIES';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const CLEAR_COMPANY_ERROR = 'CLEAR_COMPANY_ERROR';

export const CERTIFICATION_ERROR = 'CERTIFICATION_ERROR';
export const UPDATE_CERTIFICATION = 'UPDATE_CERTIFICATION';

export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_ALL_EMPLOYEES = 'GET_ALL_EMPLOYEES';
export const ADD_EMPLOYEES = 'ADD_EMPLOYEES';
export const REMOVE_EMPLOYEES = 'REMOVE_EMPLOYEES';
export const EMPLOYEES_ERROR = 'EMPLOYEES_ERROR';
export const UPDATE_EMPLOYEES = 'UPDATE_EMPLOYEES';
export const GET_COMPANY_EMPLOYEES = 'GET_COMPANY_EMPLOYEES';
export const GET_BROKER_EMPLOYEES = 'GET_BROKER_EMPLOYEES';
export const UPLOAD_CENSUS = 'UPLOAD_CENSUS';
export const CENSUS_FAILURES = 'CENSUS_FAILURES';

export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const USERS_ERROR = 'USERS_ERROR';
export const GET_USERS_VIEW = 'GET_USERS_VIEW';

export const SET_VIEW = 'SET_VIEW';
export const VIEW_ERROR = 'VIEW_ERROR';

export const SET_ACTION_MODE = 'SET_ACTION_MODE';
export const ACTION_ERROR = 'ACTION_ERROR';

export const SET_CONFIG = 'SET_CONFIG';
export const GET_CONFIG = 'GET_CONFIG';
export const CONFIG_ERROR = 'CONFIG_ERROR';

// Form Actions
export const SET_EDITING = 'SET_EDITING';
export const SET_MERGING = 'SET_MERGING';
export const SET_DIRTY   = 'SET_DIRTY';
export const EDITING_ERROR = 'EDITING_ERROR';
export const MERGING_ERROR = 'MERGING_ERROR';
export const RESET_FORM_ACTIONS = 'RESET_FORM_ACTIONS';

// Form Data Actions
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const FORM_DATA_ERROR = 'FORM_DATA_ERROR';
export const RESET_FORM_DATA = 'RESET_FORM_DATA';
