
import { SET_EDITING, EDITING_ERROR, SET_MERGING, RESET_FORM_ACTIONS, SET_DIRTY } from '../types';

const initialState = {
  editing: false,
  merging: false,
  loading: true,
  dirty:   false
};

export default function formActions (state = initialState, action) {
  switch (action.type) {
    case SET_EDITING:
      return {
        ...initialState,
        editing: action.payload,
        loading: false
      };
    case SET_MERGING:
      return {
        ...initialState,
        merging: action.payload,
        loading: false
      };
    case SET_DIRTY:
      return {
        ...state,
        dirty: action.payload
      };
    case RESET_FORM_ACTIONS:
      return { ...initialState };
    case EDITING_ERROR:
      return {
        loading: false,
        error:   action.payload
      };
    default: return state;
  }
}
