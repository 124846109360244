
import { SET_ACTION_MODE, ACTION_ERROR } from '../types';

const initialState = {
  mode:    null,
  loading: true
};

export default function viewChanger (state = initialState, action) {
  switch (action.type) {
    case SET_ACTION_MODE:
      return {
        ...state,
        mode:      action.payload?.mode ? action.payload.mode : action.payload,
        companyID: action.payload?.companyID,
        brokerId:  action.payload?.brokerId,
        loading:   false
      };
    case ACTION_ERROR:
      return {
        loading: false,
        error:   action.payload
      };
    default: return state;
  }
}
