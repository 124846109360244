import { GET_CONFIG, CONFIG_ERROR } from '../types';

export const getConfig = ({ query = false, session = false } = {}) => async (dispatch, getState) => {
  const state = await getState();
  let companyUser = await state.configList.configs.title === 'companyUser' ? await state.configList.configs.userId : false;

  if (!query) {
    return dispatch({
      type:    GET_CONFIG,
      payload: {
        companyQuery: false,
        brokerQuery:  false,
        role:         await state.configList.configs.role,
        title:        await state.configList.configs.title,
        isAdmin:      await state.configList.configs.isAdmin,
        userId:       await state.configList.configs.userId,
        brokerIds:    await state.configList.configs.brokerIds
      }
    });
  }

  try {
    let obj;
    if (query.broker) {
      let brokers = await state.userList.dataByName?.users?.brokers || [];
      let queryBroker = await (brokers || []).filter(({ id }) => query.broker === id)[0];

      obj = {
        parameter: query.broker,
        broker:    queryBroker
      };
    }
    let compObject;
    if (query.company) {
      let companies = await state.companyList?.dataByName?.company?.companies;
      let queryCompany = (companies || []).filter(({ id }) => query.company === id)[0];

      compObject = {
        parameter: query.company,
        company:   queryCompany
      };
    }

    if (companyUser) {
      let companies = await state.companyList.dataByName.company.companies;
      let queryCompany = (companies || []).filter(({ contactId }) => companyUser.toString() === contactId.toString())[0];

      compObject = {
        parameter: companyUser,
        company:   queryCompany
      };
    }

    const config = {
      companyQuery: !!query.company || companyUser ? compObject : false,
      brokerQuery:  query.broker ? obj : false,
      role:         session.user.role,  //broker, company, admin
      title:        session.user.role === 'broker' ? (session.brokerIds || []).length ? 'csr' : 'broker' : session.user.role === 'company' ? 'companyUser' : session.user.role, /// csr, companyUser, broker, admin
      isAdmin:      session.user.role === 'admin',
      userId:       session.user.id,
      brokerIds:    session.user.role === 'broker' && (session.brokerIds || []).length  ? session.brokerIds : []
    };

    return dispatch({
      type:    GET_CONFIG,
      payload: config
    });
  } catch (error) {
    dispatch({
      type:    CONFIG_ERROR,
      payload: error.message
    });
  }
};
