import { SET_VIEW, VIEW_ERROR } from '../types';
// import { relationships } from '../../config/statics';

export const setCurrentView = (props) =>  async (dispatch, getState) => {
  const state = await getState();
  try {
    return dispatch({
      type:    SET_VIEW,
      payload: {
        view:    props?.view ? props.view : props,
        results: props?.results,
        pages:   props?.pages
      }
    });
  } catch (error) {
    dispatch({
      type:    VIEW_ERROR,
      payload: error.message
    });
  }
};
