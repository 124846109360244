
import { combineReducers } from 'redux';
import viewChangerReducer from './viewChangerReducer';
import configReducer from './configReducer';
import actionatorModeReducer from './actionatorModeReducer';
import formReducer from './formReducer';
import formDataReducer from './formDataReducer';
import certSlice from './certificationReducer';
import compSlice from './companyReducer';
import userSlice from './userReducerSlice';
import employeeReducerSlice from './employeeReducerSlice';
import changeReqReducer from './changeRequestReducer';

export default combineReducers({
  employeeList:   employeeReducerSlice,
  currentView:    viewChangerReducer,
  configList:     configReducer,
  actionatorMode: actionatorModeReducer,
  formActions:    formReducer,
  formData:       formDataReducer,
  certList:       certSlice,
  companyList:    compSlice,
  userList:       userSlice,
  changeReqList:  changeReqReducer
});
